import { render, staticRenderFns } from "./sdComInsurance.vue?vue&type=template&id=5d462518&scoped=true"
import script from "./sdComInsurance.vue?vue&type=script&lang=js"
export * from "./sdComInsurance.vue?vue&type=script&lang=js"
import style0 from "./sdComInsurance.vue?vue&type=style&index=0&id=5d462518&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d462518",
  null
  
)

export default component.exports