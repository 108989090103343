<template>
  <div>
    <SimpleTable ref="table" :tableProps="tableProps" :queryFun="queryFun">
      <template slot="left-r">
        <el-button
          class="add-btn"
          icon="el-icon-circle-plus-outline"
          @click="toDetail(true)"
          >添加</el-button
        >
      </template>
      <template slot="action" slot-scope="{ data }">
        <el-button @click="toDetail(false, data.row)" type="text"
          >查看详情</el-button
        >
        <el-button type="text" @click="toDetail(true, data.row)"
          >编辑</el-button
        >
        <el-button @click="deleteCom(data.row)" type="text">删除</el-button>
      </template>
    </SimpleTable>
  </div>
</template>

<script>
import SimpleTable from "./components/SimpleTable";
import {
  getInsuranceCompanyList,
  delInsuranceCompany,
} from "@/api/safeDuty.js";
export default {
  components: { SimpleTable },
  data() {
    return {
      tableProps: {
        fuzzyQueryKey: "fuzzySearch",
        currentPageKey: "current",
        currentSizeKey: "size",
        isFuzzyQuery: false,
        totalKey: "total",
        height: 540,
        searchConfigs: [
          {
            label: "保险公司",
            type: "input",
            modelKey: "companyName",
          },
          {
            label: "分支机构",
            type: "input",
            modelKey: "branch",
          },
          {
            label: "更新时间",
            type: "daterange",
            daterangeKeys: ["updateTimeStart", "updateTimeEnd"],
            modelKey: "updateTime",
          },
          {
            label: "录入时间",
            type: "daterange",
            daterangeKeys: ["createTimeStart", "createTimeEnd"],
            modelKey: "createTime",
          },
          {
            label: "录入人",
            type: "input",
            modelKey: "createUserName",
          },
        ],
        columnProps: [
          {
            prop: "",
            label: "序号",
            width: "55",
            type: "index",
          },
          {
            prop: "companyName",
            label: "保险公司",
            width: "",
            type: "",
            //slot: true,
          },

          {
            prop: "branch",
            label: "分支机构",
            width: "",
            type: "",
            //slot: true,
          },
          {
            prop: "optionNumber",
            label: "险别数量",
            width: "",
            type: "",
            //slot: true,
          },
          {
            prop: "riskOptionNumber",
            label: "风险减量方案数量",
            width: "",
            type: "",
            //slot: true,
          },
          {
            prop: "projectNumber",
            label: "项目数量",
            width: "",
            type: "",
            //slot: true,
          },
          {
            prop: "createUserName",
            label: "录入人",
            width: "",
            type: "",
            //slot: true,
          },
          {
            prop: "createTime",
            label: "录入时间",
            width: "",
            type: "",
            //slot: true,
          },
          {
            prop: "updateTime",
            label: "更新时间",
            width: "",
            type: "",
            //slot: true,
          },
          {
            prop: "action",
            label: "操作",
            width: "180px",
            type: "",
            slot: true,
          },
        ],
      },
    };
  },
  methods: {
    async queryFun(queryParams) {
      let res = await getInsuranceCompanyList(queryParams);
      return {
        localData: res.data.records || [{}],
        total: res.data.total,
      };
    },
    deleteCom(item) {
      this.$confirm("删除后无法添加该保司新的保单，是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delInsuranceCompany({ id: item.id }).then(() => {
          this.$refs.table.getData();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    // 跳转详情或编辑
    toDetail(edit = false, item = {}) {
      this.$router.push({
        name: "safeDutyComManagementDetail",
        params: {
          id: item?.id,
          isEdit: edit,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
  background: #00bc0d;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #00bc0d;
}
.dele-btn {
  background: #c94242;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #c94242;
}
.export-btn {
  background: #4278c9;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  border-color: #4278c9;
}
</style>
